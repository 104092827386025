import React from "react";
import { css } from "@emotion/core";
import {
  font,
  color,
  mediaQuery,
  smartPhone,
} from "../constants/styles";

const wrapper = css`
  width: 236px;
  margin: 0 auto;
  text-align: center;
  ${mediaQuery[smartPhone]} {
    display: flex;
    justify-content: center;
    width: 90%;
    margin: 0 auto;
  }
`

const imageAria = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 236px;
  height: 236px;
  margin: 0 auto;
  background: ${color.white};
  border-radius: 50%;
  ${mediaQuery[smartPhone]} {
    width: 77px;
    height: 77px;
  }
`

const textAria = css`
  margin: 0 auto;
  ${mediaQuery[smartPhone]} {
    width: 186px;
    text-align: start;
  }
`

const mainText = css`
  display: block;
  max-width: 150px;
  margin: 42px auto;
  font-family: 'Noto Serif JP', serif;
  font-size: ${font.subtitleSize};
  color: ${color.mainD};
  line-height: 36px;
  ${mediaQuery[smartPhone]} {
    font-size: 14px;
    line-height: 150%;
    margin: 0 0 16px 0;
  }
`;

const subText = css`
  font-size: ${font.textsize2};
  color: ${color.black};
  line-height: 27px;
  ${mediaQuery[smartPhone]} {
    font-size: ${font.ButtonTextSize};
    line-height: 150%;
  }
`

export const AboutOpeningBusinessCard = (props) => {
  return (
    <div css={wrapper}>
      <div css={imageAria}>
        <img src={props.image}></img>
      </div>
      <div css={textAria}>
        <p css={mainText}>{props.mainText}</p>
        <p css={subText}>{props.subText}</p>
      </div>
    </div>
  );
};
import React from "react";
import { css } from "@emotion/core";
import { Link } from "gatsby";
import {
  font,
  color,
  mediaQuery,
  smartPhone,
} from "../constants/styles";
import { AboutOpeningBusinessCard } from "./AboutOpeningBusinessCard";
import backgroundImage1Phone from "../images/home-5-1-phone.png";
import backgroundImage2Phone from "../images/home-5-2-phone.png";
import backgroundImage3Phone from "../images/home-5-3-phone.png";
import backgroundImage4Phone from "../images/home-5-4-phone.png";
import backgroundImage1 from "../images/home-5-1.png";
import backgroundImage2 from "../images/home-5-2.png";
import backgroundImage3 from "../images/home-5-3.png";
import backgroundImage4 from "../images/home-5-4.png";
import backgroundImage5 from "../images/home-5-5.png";
import triBlack from "../images/tri-black.png";
import triBlackPhone from "../images/tri-black-phone.png";

const wrapper = css`
  max-width: 1480px;
  width: 100%;
  background: ${color.whiteGray};
  margin: 0 auto;
  padding-bottom: 128px;
  text-align: center;
`;

const container = css`
  width: 90%;
  margin: 0 auto;
  ${mediaQuery[smartPhone]} {
    margin-top: -220px;
  }
`;

const imageAria = css`
  margin: 0 auto;
  width: 100%;
  height: 469px;
  background-image: url(${backgroundImage5});
`;

const titleAria = css`
  margin: 0 auto;
  padding-top: 277px;
  ${mediaQuery[smartPhone]} {
    padding-top: 98px;
  }
`;

const subTitle = css`
  font-family: 'Noto Serif JP', serif;
  font-size: ${font.textSize};
  font-weight: bold;
  color: #5F5450;
  line-height: 20px;
  ${mediaQuery[smartPhone]} {
    font-size: 10px;
    line-height: 14px;
  }
`;

const title = css`
  display: block;
  padding-top: 24px;
  padding-bottom: 26px;
  font-family: 'Noto Serif JP', serif;
  font-size: ${font.NomaltitleSize};
  color: ${color.mainD};
  line-height: 52px;
  ${mediaQuery[smartPhone]} {
    font-size: 14px;
    line-height: 20px;
    padding-top: 16px;
    padding-bottom: 20px;
  }
`;

const titleBorder = css`
  margin: 0 auto;
  width: 80px;
  height: 2px;
  background: ${color.mainD};
  ${mediaQuery[smartPhone]} {
    width: 60px;
    height: 1px;
  }
`;

const AboutOpeningBusinessCardsWrapperCp = css`
  display: flex;
  justify-content: space-between;
  padding-top: 67px;
  ${mediaQuery[smartPhone]} {
    display: none;
  }
`;

const AboutOpeningBusinessCardsWrapperSp = css`
  display: none;
  ${mediaQuery[smartPhone]} {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;

const btn = css`
  width: 479px;
  height: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 88px auto 0 auto;
  background-color: ${color.white};
  border: 1px solid ${color.mainD};
  color: ${color.mainD};
  border-radius: 4px;
  transition: 0.25s;
  :hover {
    background-color: ${color.mainD};
    border: 1px solid ${color.white};
    color: ${color.white};
  }
  ${mediaQuery[smartPhone]} {
    width: 279px;
    height: 38px;
  }
`

const btnText = css`
  display: block;
  font-size: ${font.textsize2};
  line-height: 22px;
  ${mediaQuery[smartPhone]} {
    font-size: 10px;
    line-height: 12px;
  }
`

const tri = css`
  display: block;
  justify-content: center;
  align-items: center;
  width: 8.54px;
  height: 17.08px;
  padding-top: 112px;
  ${mediaQuery[smartPhone]} {
    width: 8.33px;
    height: 4.17px;
    padding-top: 0;
    padding: 16px 0;
  }
`

export const AboutOpeningBusiness = () => {
  return (
    <div css={wrapper}>
        <div css={imageAria}>
          <div css={titleAria}>
            <p css={subTitle}>ABOUT OPENING BUSINESS</p>
            <h3 css={title}>開業までの流れ</h3>
            <div css={titleBorder}></div>
          </div>
        </div>
      <div css={container}>
        <div css={AboutOpeningBusinessCardsWrapperCp}>
          <AboutOpeningBusinessCard
            mainText="開業地選定"
            subText="先生のご希望の地域から開業地を選定できます。"
            image={backgroundImage1}
          />
          <img css={tri} src={triBlack}></img>
          <AboutOpeningBusinessCard
            mainText="事業計画策定"
            subText="採算性を重視した収支の想定を行い、より安定的な医院経営にむけた支援を致します。"
            image={backgroundImage2}
          />
          <img css={tri} src={triBlack}></img>
          <AboutOpeningBusinessCard
            mainText="行政機関への各種届出"
            subText="医院開設に必要な書類の作成・提出をサポート致します。"
            image={backgroundImage3}
          />
          <img css={tri} src={triBlack}></img>
          <AboutOpeningBusinessCard
            mainText="開業"
            subText="スタッフの研修、検査シミュレーション実施等のサポートの後、開業致します。"
            image={backgroundImage4}
          />
        </div>
        <div css={AboutOpeningBusinessCardsWrapperSp}>
          <AboutOpeningBusinessCard
            mainText="開業地選定"
            subText="先生のご希望の地域から開業地を選定できます。"
            image={backgroundImage1Phone}
          />
          <img css={tri} src={triBlackPhone}></img>
          <AboutOpeningBusinessCard
            mainText="事業計画策定"
            subText="採算性を重視した収支の想定を行い、より安定的な医院経営にむけた支援を致します。"
            image={backgroundImage2Phone}
          />
          <img css={tri} src={triBlackPhone}></img>
          <AboutOpeningBusinessCard
            mainText="行政機関への各種届出"
            subText="医院開設に必要な書類の作成・提出をサポート致します。"
            image={backgroundImage3Phone}
          />
          <img css={tri} src={triBlackPhone}></img>
          <AboutOpeningBusinessCard
            mainText="開業"
            subText="スタッフの研修、検査シミュレーション実施等のサポートの後、開業致します。"
            image={backgroundImage4Phone}
          />
        </div>
        <Link to="/OpeningSupport/">
          <div css={btn}>
            <p css={btnText}>詳しく見る</p>
          </div>
        </Link>
      </div>
    </div>
  );
};

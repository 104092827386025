import React from "react";
import { Link } from "gatsby";
import { css } from "@emotion/core";
import {
  font,
  color,
  mediaQuery,
  smartPhone,
} from "../constants/styles";
import backgroundImage from "../images/home-1-1.png";
import backgroundImagePhone from "../images/home-1-1-phone.png";

const wrapper = css`
  max-width: 1480px;
  width: 100%;
  height: 816px;
  text-align: center;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(${backgroundImage});
  background-size: cover;
  ${mediaQuery[smartPhone]} {
    height: 194px;
    padding-top: 62px;
    background-image: url(${backgroundImagePhone});
    background-size: 100%;
  }
`;

const mainTextAria = css`
  margin-bottom: 60px;
  ${mediaQuery[smartPhone]} {
    margin-bottom: 0;
  }
`;

const mainText = css`
  font-family: 'Noto Serif JP', serif;
  font-size: ${font.NomaltitleSize};
  color: #362931;
  line-height: 200%;
  letter-spacing: 0.2em;
  text-shadow: 0px 0px 8px ${color.white};
  ${mediaQuery[smartPhone]} {
    font-size: 18px;
  }
`;

const homeTopForm = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 257px;
  height: 71px;
  border: 1px solid ${color.mainD};
  background-color: ${color.mainD};
  color: ${color.white};
  border-radius: 35.5px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  transition: 0.25s;
  :hover {
    background-color: ${color.white};
    color: ${color.mainD};
  }
  ${mediaQuery[smartPhone]} {
    display: none;
  }
`;

const tri = css`
  display: block;
  font-size: ${font.ButtonTextSize};
  line-height: 22px;
  padding-right: 12px;
`

const homeTopFormText = css`
  display: block;
  font-size: ${font.textsize2};
  line-height: 22px;
`;

export const HomeTop = () => {
  return (
    <div css={wrapper}>
      <div css={mainTextAria}>
        <h2 css={mainText}>
          奈良県内に特化した<br></br>
          継承・開業をサポートします
        </h2>
      </div>
      <Link to="/Contact/">
        <div css={homeTopForm}>
          <p css={tri}>▶</p>
          <p css={homeTopFormText}>お問い合わせ</p>
        </div>
      </Link>
    </div>
  );
};
import React from "react";
import { css } from "@emotion/core";
import { font, color, mediaQuery, smartPhone } from "../constants/styles";
import { TopPageNewsTitles } from "./TopPageNewsTitles";
import backgroundImage from "../images/home-6-1.png";
import backgroundImagePhone from "../images/home-6-1-phone.png";

const wrapper = css`
  width: 100%;
  background-image: url(${backgroundImage});
  background-size: cover;
  ${mediaQuery[smartPhone]} {
    background-image: url(${backgroundImagePhone});
  }
`;

const container = css`
  width: 90%;
  margin: 0 auto;
  text-align: center;
`;

const titleAria = css`
  padding-top: 113px;
  padding-bottom: 72px;
  ${mediaQuery[smartPhone]} {
    padding-top: 55px;
    padding-bottom: 67px;
  }
`;

const subTitle = css`
  font-family: 'Noto Serif JP', serif;
  font-size: ${font.textSize};
  font-weight: bold;
  color: #5F5450;
  line-height: 20px;
  ${mediaQuery[smartPhone]} {
    font-size: 10px;
    line-height: 14px;
  }
`;

const title = css`
  display: block;
  padding-top: 24px;
  padding-bottom: 26px;
  font-family: 'Noto Serif JP', serif;
  font-size: ${font.NomaltitleSize};
  color: ${color.mainD};
  line-height: 52px;
  ${mediaQuery[smartPhone]} {
    font-size: 14px;
    line-height: 20px;
    padding-top: 16px;
    padding-bottom: 20px;
  }
`;

const titleBorder = css`
  margin: 0 auto;
  width: 80px;
  height: 2px;
  background: ${color.gray};
  ${mediaQuery[smartPhone]} {
    width: 60px;
    height: 1px;
  }
`;

const newsTitlesContainer = css`
  padding-bottom: 145px;
  ${mediaQuery[smartPhone]} {
    padding-bottom: 67px;
  }
`

export const TopPageNewsBlock = (props) => {
  return (
    <section css={wrapper}>
      <div css={container}>
        <div css={titleAria}>
          <p css={subTitle}>News</p>
          <h3 css={title}>おしらせ</h3>
          <div css={titleBorder}></div>
        </div>
        <div css={newsTitlesContainer}>
          <TopPageNewsTitles props={props} />
        </div>
      </div>
    </section>
  );
};

import React from "react";
import { css } from "@emotion/core";
import { font, color, mediaQuery, smartPhone } from "../constants/styles";
import { Link } from "gatsby";
import { CaseStudyCardsFormBtn } from "./CaseStudyCardsFormBtn";
import home41 from "../images/home-4-1.png";

const wrapper = css`
  width: 100%;
`;

const container = css`
  width: 90%;
  padding-bottom: 63px; 
  margin: 0 auto;
  text-align: center;
  background-image: url(${home41});
  background-repeat: no-repeat;
  ${mediaQuery[smartPhone]} {
    background-image: none;
    padding-bottom: 14px;
  }
`;

const formBtnsWrapper = css`
  display: grid;
  grid-template-columns: repeat(5, 218px);
  grid-gap: 24px;
  justify-content: center;
  ${mediaQuery[smartPhone]} {
    grid-template-columns: repeat(2, 141px);
    grid-gap: 16px;
  }
`;

const titleAria = css`
  padding-top: 180px;
  margin-bottom: 72px;
  ${mediaQuery[smartPhone]} {
    padding-top: 32px;
    margin-bottom: 41px;
  }
`;

const subTitle = css`
  font-family: 'Noto Serif JP', serif;
  font-size: ${font.textSize};
  font-weight: bold;
  color: #5F5450;
  line-height: 20px;
  ${mediaQuery[smartPhone]} {
    font-size: 9px;
    line-height: 13px;
  }
`;

const title = css`
  display: block;
  padding: 24px 0;
  font-family: 'Noto Serif JP', serif;
  font-size: ${font.NomaltitleSize};
  color: ${color.mainD};
  line-height: 52px;
  ${mediaQuery[smartPhone]} {
    font-size: 18px;
    padding: 16px 0;
  }
`;

const titleBorder = css`
  margin: 0 auto;
  width: 80px;
  height: 2px;
  background: ${color.gray};
  ${mediaQuery[smartPhone]} {
    width: 60px;
    height: 1px;
  }
`;


export const CaseStudyCards = () => {
  return (
    <div css={wrapper}>
      <div css={container}>
        <div css={titleAria}>
          <p css={subTitle}>ABOUT OPENING BUSINESS</p>
          <h3 css={title}>開業をお考えの方へ</h3>
          <div css={titleBorder}></div>
        </div>
        <div css={formBtnsWrapper}>
          <Link to={`/CaseStudies/CS_Internal`}>
            <CaseStudyCardsFormBtn title="内科" />
          </Link>
          <Link to={`/CaseStudies/CS_Orthopedics`}>
            <CaseStudyCardsFormBtn title="整形外科" />
          </Link>
          <Link to={`/CaseStudies/CS_Neurosurgery`}>
            <CaseStudyCardsFormBtn title="脳神経外科" />
          </Link>
          <Link to={`/CaseStudies/CS_Otolaryngology`}>
            <CaseStudyCardsFormBtn title="耳鼻咽喉科" />
          </Link>
          <Link to={`/CaseStudies/CS_Pediatrics`}>
            <CaseStudyCardsFormBtn title="小児科" />
          </Link>
          <Link to={`/CaseStudies/CS_Gynecology`}>
            <CaseStudyCardsFormBtn title="婦人科・乳腺外科" />
          </Link>
          <Link to={`/CaseStudies/CS_Urology`}>
            <CaseStudyCardsFormBtn title="泌尿器科" />
          </Link>
          <Link to={`/CaseStudies/CS_Dermatology`}>
            <CaseStudyCardsFormBtn title="皮膚科・形成外科" />
          </Link>
          <Link to={`/CaseStudies/CS_Psychosomatic`}>
            <CaseStudyCardsFormBtn title="心療内科" />
          </Link>
          <Link to={`/CaseStudies/CS_Ocular`}>
            <CaseStudyCardsFormBtn title="眼科" />
          </Link>
        </div>
      </div>
    </div>
  );
};

import React from "react";
import { css } from "@emotion/core";
import {
  font,
  color,
  mediaQuery,
  smartPhone,
} from "../constants/styles";
import home31 from "../images/home-3-1.png";

const wrapper = css`
  max-width: 1480px;
  width: 100%;
  height: 764px;
  background-image: url(${home31});
  background-size: cover;
  ${mediaQuery[smartPhone]} {
    height: auto;
  }
`;

const container = css`
  width: 90%;
  margin: 0 auto;
`;

const sectionContainer = css`
  width: 60%;
  height: calc(100% - 146px);
  background-color: rgba(127, 54, 83, 0.8);
  padding-top: 146px;
  ${mediaQuery[smartPhone]} {
    width: 100%;
    height: 536px;
    padding-top: 60px;
  }
`;

const title = css`
  font-family: 'Noto Serif JP', serif;
  font-size: ${font.BigtitleSize};
  color: ${color.white};
  ${mediaQuery[smartPhone]} {
    font-size: 18px;
  }
`;

const border = css`
  width: 137px;
  height: 2px;
  background-color: ${color.pink};
  margin: 24px 0;
  ${mediaQuery[smartPhone]} {
    height: 1px
    margin: 16px 0;
  }
`;

const subTitle = css`
  font-family: 'Noto Serif JP', serif;
  font-size: ${font.subtitleSize};
  color: ${color.white};
  ${mediaQuery[smartPhone]} {
    font-size: 14px;
  }
`;

const text = css`
  display: block;
  padding: 50px;
  width: 560px;
  margin-top: 50px;
  color: ${color.white};
  font-size: ${font.textSize};
  line-height: 200%;
  border: 1px solid ${color.white};
  ${mediaQuery[smartPhone]} {
    width: 253px;
    font-size: ${font.ButtonTextSize};
    padding: 40px 33px;
    margin: 32px auto 0 auto;
  }
`;

export const AboutUs = () => {
  return (
    <div css={wrapper}>
      <div css={sectionContainer}>
        <div css={container}>
          <h3 css={title}>About us</h3>
          <div css={border}></div>
          <p css={subTitle}>
            当組合は奈良県内で医業をされる先生方のための<br></br>事業協同組合です。
          </p>
          <p css={text}>
            昭和35年から奈良県内の医療機関・医療経営をサポートしてきました。
            今後新たに開業をお考えの先生に、診察器械から消耗品まで医療現場・医療事務機関
            において　必要とされる最適なサービスを専門的な立場からご提案・ご提供いたします。
            開業までの支援だけではなく開業後も様々な分野でサポートいたします。
          </p>
        </div>
      </div>
    </div>
  );
};

import React, { useState, useEffect } from 'react'
import { css } from '@emotion/core'
import { font, color, mediaQuery, smartPhone } from '../constants/styles'
import { Layout } from '../components/Layout'
import { Link } from 'gatsby'
import { Head } from '../components/Head'
import { MainContents } from '../components/MainContents'
import { AboutUs } from '../components/AboutUs'
import { CaseStudyCards } from '../components/CaseStudyCards'
import { TopPageNewsBlock } from '../components/TopPageNewsBlock'
import { AboutOpeningBusiness } from '../components/AboutOpeningBusiness'
import { HomeTop } from '../components/HomeTop'
import email from '../images/email.svg'
import arrowRight from '../images/arrow-right.svg'

const loading = css`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  z-index: 1;
`

const formLinkPhone = css`
  display: none;
  ${mediaQuery[smartPhone]} {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 55px;
    background-color: ${color.mainD};
    position: fixed;
    bottom: 0;
    z-index: 1000;
  }
`

const emailImage = css`
  width: 20px;
  height: 16px;
`

const formTitle = css`
  font-family: 'Noto Serif JP', serif;
  font-size: 14px;
  line-height: 20px;
  color: ${color.white};
  letter-spacing: 0.3em;
`

const arrowRightImage = css`
  width: 8px;
  height: 14px;
`

export default function Home({ data, location }) {
  const siteMetadata = data?.site?.siteMetadata
  const [pathname, setPathname] = useState('')
  useEffect(() => {
    setPathname(location.pathname)
  }, [])
  return (
    <div className={loading} id="load">
      <Head props={siteMetadata} />
      <Layout location={pathname}>
        <HomeTop />
        <MainContents />
        <AboutUs />
        <CaseStudyCards />
        <AboutOpeningBusiness />
        <TopPageNewsBlock news={data} />
      </Layout>
      <Link to={`/Contact/`}>
        <div css={formLinkPhone}>
          <img css={emailImage} src={email}></img>
          <p css={formTitle}>お問い合わせはこちら</p>
          <img css={arrowRightImage} src={arrowRight}></img>
        </div>
      </Link>
    </div>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl,
        description
      }
    }
    allMicrocmsNews {
      nodes {
        daytime
        newsImage1 {
          url
        }
        newsImage2 {
          url
        }
        newsImage3 {
          url
        }
        newsImage4 {
          url
        }
        newsText
        newsTitle
        id
      }
    }
  }
`

import React from "react";
import { css } from "@emotion/core";
import { font, color, mediaQuery, smartPhone } from "../constants/styles";
const dayjs = require("dayjs");

const container = css`
  display: flex;
  padding: 26px 0;
  font-size: ${font.textSize};
  line-height: 27px;
  border-bottom: 1px solid #BDBDBD;
  ${mediaQuery[smartPhone]} {
    font-size: ${font.ButtonTextSize};
  }
`;

const newsDate = css`
  color: ${color.mainD};
  padding-left: 80px;
  ${mediaQuery[smartPhone]} {
    padding-left: 20px;
  }
`

const newsTitle = css`
  color: ${color.black};
  padding-left: 234px;
  ${mediaQuery[smartPhone]} {
    padding-left: 18px;
  }
`;

export const TopPageNewsTitles = ({ props }) => {
  const newsData = props.news.allMicrocmsNews.nodes;
  const sortData = newsData.sort(function (a, b) {
    if (a.daytime > b.daytime) {
      return -1;
    } else {
      return 1;
    }
  });
  return sortData?.map((data) => {
    const date = dayjs(data.daytime).format("YYYY.MM.DD");
    return (
      <div key={data.id} css={container}>
        <div css={newsDate}>{date}</div>
        <div css={newsTitle}>{data.newsTitle}</div>
      </div>
    );
  });
};

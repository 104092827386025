import React from "react";
import { css } from "@emotion/core";
import { Link } from "gatsby";
import {
  font,
  color,
  mediaQuery,
  smartPhone,
} from "../constants/styles";
import { FormBtn } from "./FormBtn";
import home22 from "../images/home-2-2.png";

const wrapper = css`
  max-width: 343px;
`

const mainContentsCardLogo = css`
  width: 55px;
  height: 55px;
  background-image: url(${home22});
  background-size: cover;
  ${mediaQuery[smartPhone]} {
    width: 30px;
    height: 30px;
    margin: 16px 0;
  }
`

const mainText = css`
  display: block;
  font-family: 'Noto Serif JP', serif;
  font-size: ${font.subtitleSize};
  color: ${color.mainD};
  line-height: 36px;
  ${mediaQuery[smartPhone]} {
    font-size: 18px;
    line-height: 150%;
  }
`;

const border = css`
  width: 220px;
  height: 1px;
  background-color: ${color.gray};
  margin: 12px 0;
  ${mediaQuery[smartPhone]} c{
    margin: 16px 0;
  }
  `;

const subText = css`
  display: block;
  padding-bottom: 50px;
  font-size: ${font.textSize};
  color: ${color.black};
  line-height: 160%;
  ${mediaQuery[smartPhone]} {
    font-size: ${font.ButtonTextSize};
    padding-bottom: 16px;
  }
`

export const MainContentsCard = (props) => {
  return (
    <div css={wrapper}>
      <div css={mainContentsCardLogo}></div>
      <p css={mainText}>{props.mainText}</p>
      <div css={border}></div>
      <p css={subText}>{props.subText}</p>
      <Link to={props.link}>
        <FormBtn title="詳しく見る" />
      </Link>
    </div>
  );
};
import React from "react";
import { css } from "@emotion/core";
import { font, color, mediaQuery, smartPhone } from "../constants/styles";

const formArea = css`
  width: 218px;
  height: 50px;
  display: flex;
  justify-content: start;
  align-items: center;
  background-color: ${color.white};
  color: ${color.mainD};
  font-weight: normal;
  line-height: 2;
  border: 1px solid ${color.mainD};
  border-sizing: border-box;
  transition: 0.25s;
  :hover {
    background-color: ${color.mainD};
    border: 1px solid ${color.white};
    color: ${color.white};
  }
  ${mediaQuery[smartPhone]} {
    width: 141px;
    height: 36px;
  }
`;

const titleAria = css`
  display: flex;
  justify-content: start;
  padding-left: 36px;
  ${mediaQuery[smartPhone]} {
    padding-left: 14px;
  }
`

const tri = css`
  display: block;
  font-size: ${font.ButtonTextSize};
  line-height: 14px;
  padding-right: 20px;
  ${mediaQuery[smartPhone]} {
    font-size: 10px;
    line-height: 12px;
    padding-right: 22px;
  }
`

const formTitle = css`
  font-size: ${font.ButtonTextSize};
  line-height: 14px;
  ${mediaQuery[smartPhone]} {
    font-size: 10px;
    line-height: 12px;
  }
`;

export const CaseStudyCardsFormBtn = (props) => {
  return (
    <div css={formArea}>
      <div css={titleAria}>
        <p css={tri}>▶</p>
        <p css={formTitle}>{props.title}</p>
      </div>
    </div>
  );
};
